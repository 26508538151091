import { ReactComponent as Android } from "../../../../../../assets/images/imgly/android.svg";
import { ReactComponent as Apple } from "../../../../../../assets/images/imgly/apple.svg";
import { ReactComponent as ReactNative } from "../../../../../../assets/images/imgly/reactnative.svg";
import { ReactComponent as Flutter } from "../../../../../../assets/images/imgly/flutter.svg";
import * as React from 'react';
export default {
  Android,
  Apple,
  ReactNative,
  Flutter,
  React
};